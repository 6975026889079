import { useMutation } from '@apollo/client'
import { useEffect } from 'react'

import { useQueryParams } from 'hooks'

import { VerifyAccount, VERIFY_ACCOUNT, VerifyAccountVariables } from '../api'

export const useVerifyAccount = () => {
  const query = useQueryParams()
  const token = query.get('token')
  const emailToValidate = query.get('email') ?? undefined

  const [verifyAccount, { loading, data }] = useMutation<VerifyAccount, VerifyAccountVariables>(
    VERIFY_ACCOUNT,
    {
      errorPolicy: 'all',
    },
  )

  useEffect(() => {
    if (token) {
      verifyAccount({ variables: { token } })
    }
  }, [token, verifyAccount])

  const isAccountVerified = !!data?.verifyAccount.success

  const accountVerificationText = isAccountVerified
    ? 'Has validado tu mail, ya puedes iniciar sesión'
    : 'Tu mail no ha podido ser verificado, ante cualquier duda escríbenos a info@kuna.app'

  return {
    loading,
    isAccountVerified,
    accountVerificationText,
    isVerification: !!token,
    emailToValidate,
  }
}
