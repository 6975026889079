import styled from 'styled-components'

export { LotPopup } from './LotPopup'
export { LeftSideBar } from './LeftSideBar'
export { RightSideBar } from './RightSideBar'
export { DrawnAreasMarker } from './DrawnAreasMarker'
export { Control } from './Control'
export { LotsLoader } from './LotsLoader'
export { LuckerLotPopup } from './LuckerLotPopup'

export const Container = styled.div`
  width: 100%;
`
