import { Marker } from 'react-map-gl'

import { PopupCard } from './components'
import { LuckerPopupInfo } from '../../types'

export const LuckerLotPopup: React.FC<LuckerPopupInfo> = ({
  size,
  coordinates,
  commercialExecutive,
  average,
  category,
  id,
  lotAge,
}) => {
  return (
    <Marker latitude={coordinates[1]} longitude={coordinates[0]} draggable={false}>
      <PopupCard
        average={average}
        category={category}
        id={id}
        lotAge={lotAge}
        size={size}
        commercialExecutive={commercialExecutive}
      />
    </Marker>
  )
}
