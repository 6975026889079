import { Col, Row } from 'antd'

import { EllipsisText } from 'components'
import { LuckerPopupInfo } from 'features/MainRouter/features/Map/types'

import { Container, Header, Body, Text, ColorText } from './components'

export const PopupCard: React.FC<Omit<LuckerPopupInfo, 'coordinates'>> = ({
  size,
  commercialExecutive,
  average,
  category,
  lotAge,
}) => {
  return (
    <Container>
      <Header>
        <EllipsisText
          text={'Lote de cacao'}
          overlayInnerStyle={{
            fontSize: '13px',
          }}
        />
      </Header>
      <Body gutter={[0, 16]}>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <Text>Productor</Text>
              </Col>
              <Col span={12}>
                <ColorText>{commercialExecutive}</ColorText>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <Text>Tamaño</Text>
              </Col>
              <Col span={12}>
                <ColorText>{size} ha</ColorText>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <Text>Edad</Text>
              </Col>
              <Col span={12}>
                <ColorText>{lotAge} años</ColorText>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <Text>Cultivo acompañante</Text>
              </Col>
              <Col span={12}>
                <ColorText>{category}</ColorText>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <Text>Prod. promedio</Text>
              </Col>
              <Col span={12}>
                <ColorText>{average} kg/ha</ColorText>
              </Col>
            </Row>
          </Col>
        </Row>
      </Body>
    </Container>
  )
}
