import styled from 'styled-components'
import { Row } from 'antd'

export const Container = styled.div`
  border-radius: ${({ theme }) => theme.foundation.smallBorderRadius};
  background-color: ${({ theme }) => theme.colors.baseColor[900]};
  color: ${({ theme }) => theme.colors.white};
  width: 250px;
  font-weight: 600;
`

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.baseColor[850]};
  padding: 16px;
  height: 48px;
  border-top-left-radius: ${({ theme }) => theme.foundation.smallBorderRadius};
  border-top-right-radius: ${({ theme }) => theme.foundation.smallBorderRadius};
`

export const Body = styled(Row)`
  padding: 16px;
`

export const Text = styled.span`
  font-size: 11px;
`

export const ColorText = styled(Text)`
  color: ${({ theme }) => theme.colors.baseColor[400]};
`
