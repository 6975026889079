import { LayerProps } from 'react-map-gl'
import { useTheme } from 'styled-components'

import { Lots } from 'hooks/useOrganizationAnalyticsLots'
import { MAP } from 'consts'

import { GeoUtils } from '../utils'
import luckerMockData from '../lucker-mock.json'

export const useClusters = (lots: Lots[]) => {
  const { colors } = useTheme()

  const clusters = GeoUtils.featureCollection(
    lot => ({ type: 'Point', coordinates: lot.lot.location!.coordinates }),
    lots,
  )

  const features = luckerMockData.map(feature => {
    return {
      type: 'Feature',
      properties: {
        id: feature.id.toString(),
      },
      geometry: { type: 'Point', coordinates: feature.geojson.coordinates[0][0][0] },
    }
  })

  const luckerClusters = {
    type: 'FeatureCollection',
    features,
  }

  const clusterLayer: LayerProps = {
    id: MAP.CLUSTERS.CLUSTERS_ID,
    type: 'circle',
    source: MAP.CLUSTERS.SOURCE_ID,
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        colors.secondary[600],
        100,
        colors.secondary[600],
        750,
        colors.secondary[600],
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      'circle-stroke-width': 1,
      'circle-stroke-color': colors.white,
    },
  }

  const clusterCountLayer: LayerProps = {
    id: MAP.CLUSTERS.CLUSTER_COUNT_ID,
    type: 'symbol',
    source: MAP.CLUSTERS.SOURCE_ID,
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-size': 12,
    },
  }

  const unclusteredPointLayer: LayerProps = {
    id: MAP.CLUSTERS.UNCLUSTERED_POINT_ID,
    type: 'circle',
    source: MAP.CLUSTERS.SOURCE_ID,
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': colors.secondary[600],
      'circle-radius': 4,
      'circle-stroke-width': 1,
      'circle-stroke-color': colors.white,
    },
  }

  return {
    unclusteredPointLayer,
    clusterCountLayer,
    clusterLayer,
    clusters,
    luckerClusters,
  }
}
