/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AnalyticsTimeMode {
  CURRENT = "CURRENT",
  NEXT_MONTH = "NEXT_MONTH",
  NEXT_TWO_MONTHS = "NEXT_TWO_MONTHS",
  NEXT_TWO_WEEKS = "NEXT_TWO_WEEKS",
  NEXT_WEEK = "NEXT_WEEK",
}

export enum CountryId {
  ARGENTINA = "ARGENTINA",
  BRAZIL = "BRAZIL",
  COLOMBIA = "COLOMBIA",
  DOMINICAN_REPUBLIC = "DOMINICAN_REPUBLIC",
  PANAMA = "PANAMA",
  PARAGUAY = "PARAGUAY",
  UNITED_STATES = "UNITED_STATES",
  URUGUAY = "URUGUAY",
}

export enum DeliveryCondition {
  DRY = "DRY",
  WET = "WET",
}

export enum FeatureSet {
  GRUPO_DIANA = "GRUPO_DIANA",
}

export enum GrainType {
  CRYSTAL_MEDIUM = "CRYSTAL_MEDIUM",
  LONG = "LONG",
  NO_TYPE = "NO_TYPE",
  PEARL_MEDIUM = "PEARL_MEDIUM",
  SHORT = "SHORT",
}

export enum MilestoneUpdateType {
  AUTOMATIC_CONVERSION = "AUTOMATIC_CONVERSION",
  AUTOMATIC_SENTINEL1 = "AUTOMATIC_SENTINEL1",
  MANUAL = "MANUAL",
}

export enum OrganizationCrmModuleFlag {
  AUTOMATED_MILESTONES = "AUTOMATED_MILESTONES",
  COMMERCIAL_AGENDA = "COMMERCIAL_AGENDA",
  SALES = "SALES",
  STOCK = "STOCK",
}

export enum OrganizationGeneralModuleFlag {
  FIELD_ENTITY = "FIELD_ENTITY",
  SATELLITE_IMAGERY = "SATELLITE_IMAGERY",
}

export enum ProductionType {
  CONVENTIONAL = "CONVENTIONAL",
  ORGANIC = "ORGANIC",
}

export enum RiceLotStatus {
  BURNING = "BURNING",
  DIFFERENTIATION = "DIFFERENTIATION",
  EAR_APPEARANCE = "EAR_APPEARANCE",
  EMERGENCE = "EMERGENCE",
  END_IRRIGATION = "END_IRRIGATION",
  FLOWERING = "FLOWERING",
  HARVEST = "HARVEST",
  INITIAL_FLOOD = "INITIAL_FLOOD",
  PHYSIOLOGICAL_MATURITY = "PHYSIOLOGICAL_MATURITY",
  PLANTING = "PLANTING",
  PREPARATION = "PREPARATION",
  PRE_EAR_APPEARANCE = "PRE_EAR_APPEARANCE",
  START_IRRIGATION = "START_IRRIGATION",
  VEGETATIVE_STATE_THREE = "VEGETATIVE_STATE_THREE",
}

export enum SeasonMillDeliveryType {
  NO_DELIVERY = "NO_DELIVERY",
  PARTIAL = "PARTIAL",
  TOTAL = "TOTAL",
}

export enum UserOrganizationRole {
  ADMIN = "ADMIN",
  COMMERCIAL = "COMMERCIAL",
  MILL = "MILL",
  SALES_REP = "SALES_REP",
}

export interface AssignClientInput {
  id: number;
  commercialExecutiveId: number;
}

export interface AssignGoalInput {
  commercialExecutiveId: number;
  yearMonth: string;
  goal?: number | null;
}

export interface CreateUserInput {
  email: string;
  password: string;
  name: string;
  lastName: string;
  profilePicture?: Upload | null;
}

export interface DateRangeInput {
  startDate: DateTime;
  endDate: DateTime;
}

export interface InviteOrganizationInput {
  email: string;
  role: UserOrganizationRole;
  regionId?: number | null;
}

export interface UpdateOrganizationInput {
  name?: string | null;
  logo?: Upload | null;
  logoIcon?: Upload | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
