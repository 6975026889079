/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type {
  CommercialExecutives,
  CommercialExecutives_commercialExecutives_results as CommercialExecutive,
  CommercialExecutivesVariables,
  CommercialExecutives_commercialExecutives_results_organizationRole_goals as CommercialExecutiveGoal,
} from './types/CommercialExecutives'

export const COMMERCIAL_EXECUTIVES = gql`
  query CommercialExecutives($regionId: Int, $includeGoals: Boolean!) {
    commercialExecutives(regionId: $regionId) {
      results {
        id
        name
        lastName
        profilePicture
        regions {
          id
        }
        organizationRole @include(if: $includeGoals) {
          goals {
            id
            yearMonth
            goal
          }
        }
      }
    }
  }
`
