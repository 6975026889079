import { Input, Space } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useEffect, useState } from 'react'
import { isNumber } from 'lodash'

import { CardSection, UserAvatar, Table } from 'components'
import { useFetchCommercialExecutives } from 'hooks/useCommercialExecutives/hooks'
import { NumberUtils } from 'utils'

import { getMonths } from './utils'
import { Text } from './components'
import { useAssignCommercialExecutiveGoal } from './hooks'
import { CardContainer } from '..'

type UserMonthData = {
  id: number
  profilePicture?: string
  fullName: string
  [key: `${number}-${number}`]: number | undefined
}

// With this function we will have an array of months data with the last month in the position 0, the current
// month in the position 1 and the following months according to the number passed by parameter
const monthsData = getMonths(5)

export const GoalsCard = () => {
  const { commercialExecutives } = useFetchCommercialExecutives({ includeGoals: true })
  const { assignGoal } = useAssignCommercialExecutiveGoal()
  const [usersMonthData, setUsersMonthData] = useState<UserMonthData[]>([])

  useEffect(() => {
    if (!commercialExecutives) return

    const mappedCommercialExecutives =
      commercialExecutives?.map(({ id, name, lastName, profilePicture, organizationRole }) => {
        const goals = organizationRole?.goals ?? []

        const goalsOptions = goals.reduce(
          (acc: { [key: `${number}-${number}`]: number }, { yearMonth, goal }) => {
            const yearMonthIndex = yearMonth as `${number}-${number}`
            // eslint-disable-next-line no-param-reassign
            acc[yearMonthIndex] = goal

            return acc
          },
          {},
        )

        return {
          id,
          fullName: `${name} ${lastName}`,
          profilePicture: profilePicture ?? undefined,
          ...goalsOptions,
        }
      }) ?? []

    setUsersMonthData(mappedCommercialExecutives)
  }, [commercialExecutives])

  const handleGoalChange = (commercialExecutiveIndex: number, yearMonth: string, goal: string) => {
    const usersData = [...usersMonthData]
    const yearMonthIndex = yearMonth as `${number}-${number}`

    const goalValue = Number(NumberUtils.keepOnlyNumbers(goal))

    usersData[commercialExecutiveIndex][yearMonthIndex] = goalValue === 0 ? undefined : goalValue

    setUsersMonthData(usersData)
  }

  const goalValueParser = (userData: UserMonthData, yearMonth: string) => {
    const yearMonthIndex = yearMonth as `${number}-${number}`
    const goal = userData[yearMonthIndex]

    return isNumber(goal) ? NumberUtils.localize(goal) : ''
  }

  const isFirstColumn = (index: number) => index === 0

  const columns: ColumnsType<UserMonthData> = [
    {
      title: 'Usuario',
      render: (user: UserMonthData) => (
        <Space size={20}>
          <UserAvatar src={user.profilePicture} />
          <Text>{user.fullName}</Text>
        </Space>
      ),
      width: '200px',
    },
    ...monthsData.map((value, index) => ({
      ...value,
      width: '180px',
      render: (userMonthValue: number, userData: UserMonthData, userIndex: number) => {
        return (
          <Input
            disabled={isFirstColumn(index)}
            placeholder={isFirstColumn(index) ? 'Sin objetivo' : 'Objetivo'}
            defaultValue={userMonthValue}
            value={goalValueParser(userData, value.dataIndex)}
            onChange={event => {
              handleGoalChange(userIndex, value.dataIndex, event.target.value)
            }}
            addonBefore={isFirstColumn(index) ? undefined : '$'}
            onBlur={event => {
              assignGoal({
                variables: {
                  assignGoalDTO: {
                    commercialExecutiveId: userData.id,
                    yearMonth: value.dataIndex,
                    goal: Number(NumberUtils.keepOnlyNumbers(event.target.value)) || null,
                  },
                },
              })
            }}
          />
        )
      },
    })),
  ]

  return (
    <CardContainer>
      <CardSection title="Objetivo mensual">
        <Table
          columns={columns}
          dataSource={usersMonthData}
          pagination={false}
          scroll={{ x: 1000, y: '50vh' }}
          rowKey={'id'}
        />
      </CardSection>
    </CardContainer>
  )
}
