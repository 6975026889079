import { useCallback, useEffect, useMemo, useState } from 'react'
import { polygon } from '@turf/helpers'
import bbox from '@turf/bbox'
import centroid from '@turf/centroid'
import dayjs from 'dayjs'
import { MapLayerMouseEvent, useMap as useReactMapGl } from 'react-map-gl'
import { isNil } from 'lodash'

import { MapFiltersContext } from 'features/MainRouter/contexts'
import {
  CropFilterContext,
  HistoricalSeasonFilterContext,
  RegionFilterContext,
  VarietyFilterContext,
} from 'contexts'
import { RiceLotStatus } from 'types'
import { MAP } from 'consts'
import { useOrganizationAnalyticsLots } from 'hooks'
import { Lots } from 'hooks/useOrganizationAnalyticsLots'
import { MiscUtils } from 'utils'

import { GeoUtils } from '../utils'
import { DrawingOption, LuckerPopupInfo, PopupInfo } from '../types'
import luckerMockData from '../lucker-mock.json'

export const useMap = (drawingOption: DrawingOption) => {
  const [coordinatesInfo, setCoordinatesInfo] = useState<{
    latitude: number
    longitude: number
    wasCenteredByLots: boolean
    zoom: number
  }>({
    latitude: MAP.DEFAULT_CENTER.LATITUDE,
    longitude: MAP.DEFAULT_CENTER.LONGITUDE,
    wasCenteredByLots: false,
    zoom: MAP.ZOOM.CLOSE,
  })
  const [cursor, setCursor] = useState<string>('grab')
  const [popupInfo, setPopupInfo] = useState<PopupInfo>()
  const [luckerPopupInfo, setLuckerPopupInfo] = useState<LuckerPopupInfo>()
  const { region } = RegionFilterContext.useRegionFilterContext()
  const { selectedCrop } = CropFilterContext.useCropFilterContext()
  const { selectedVarietyId } = VarietyFilterContext.useVarietyFilterContext()
  const { commercialExecutive, lotStatus, timeMode, subregion, company } =
    MapFiltersContext.useMapFiltersContext()
  const { selectedHistoricalSeason } =
    HistoricalSeasonFilterContext.useHistoricalSeasonFilterContext()

  const { data, loading } = useOrganizationAnalyticsLots({
    regionId: region?.id,
    subregionId: subregion?.id,
    commercialExecutiveId: commercialExecutive?.id,
    timeMode,
    riceLotStatus: lotStatus?.length ? lotStatus : [],
    companyId: company?.id,
    cropId: selectedCrop?.id,
    varietyId: MiscUtils.formatVarietyId(selectedVarietyId),
    seasonStartRange: selectedHistoricalSeason?.startRange,
    seasonEndRange: selectedHistoricalSeason?.endRange,
  })

  const { lotsMap } = useReactMapGl()

  const { withArea: lots, withoutArea: lotsWithoutArea } = useMemo(() => {
    const allLots = data?.organizationAnalytics.lots ?? []

    const groupedLots = allLots.reduce(
      (acc: { withArea: Lots[]; withoutArea: Lots[] }, lot) => {
        if (isNil(lot.lot.area)) {
          acc.withoutArea.push(lot)
        } else {
          acc.withArea.push(lot)
        }

        return acc
      },
      { withArea: [], withoutArea: [] },
    )

    return groupedLots
  }, [data?.organizationAnalytics.lots])

  useEffect(() => {
    if (subregion) {
      const latitude = subregion.location.coordinates[1]
      const longitude = subregion.location.coordinates[0]
      setCoordinatesInfo(prevState => {
        return {
          ...prevState,
          latitude,
          longitude,
        }
      })

      return
    }
    if (region) {
      const latitude = region.location.coordinates[1]
      const longitude = region.location.coordinates[0]
      setCoordinatesInfo(prevState => {
        return {
          ...prevState,
          latitude,
          longitude,
        }
      })

      return
    }
    setCoordinatesInfo(prevState => {
      return {
        ...prevState,
        wasCenteredByLots: false,
      }
    })
  }, [region, subregion])

  const onCenterLots = useCallback(() => {
    if (lotsMap && lots?.length) {
      const lotsFeatureCollection = GeoUtils.featureCollection(
        lot => ({ type: 'Polygon', coordinates: lot.lot.area!.coordinates }),
        lots,
      )

      // calculate the bounding box of all lots
      const [minLng, minLat, maxLng, maxLat] = bbox(lotsFeatureCollection)
      lotsMap.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 100, duration: 1000 },
      )
    }
  }, [lots, lotsMap])

  useEffect(() => {
    if (lots.length && !coordinatesInfo.wasCenteredByLots && lotsMap) {
      setCoordinatesInfo(prevState => {
        return {
          ...prevState,
          wasCenteredByLots: true,
        }
      })
      onCenterLots()
    }
  }, [coordinatesInfo.wasCenteredByLots, lots.length, lotsMap, onCenterLots])

  // This is a workaround to track when we should show popup or not based on if we are drawing or deleting with the drawing tool
  const [showPopupInfo, setShowPopupInfo] = useState(true)

  const onMouseEnter = useCallback(() => {
    setShowPopupInfo(drawingOption !== 'draw' && drawingOption !== 'delete')
    setCursor('pointer')
  }, [drawingOption])

  const onMouseLeave = useCallback(() => setCursor('grab'), [])

  const onHandleClickMap = useCallback(
    (event: MapLayerMouseEvent) => {
      if (popupInfo) {
        setPopupInfo(undefined)
        return
      }

      const { features } = event

      const feature = features?.[0]
      if (!feature) return

      const { id: clusterId } = feature.properties as { id: string }

      const { id: layerId } = feature.layer

      const id = clusterId ?? layerId

      const clickedLot = lots?.find(({ lot }) => lot.id === Number(id))
      if (!clickedLot) return
      const { lot, status } = clickedLot
      const bboxLot = GeoUtils.bboxCoords(bbox(lot.area))
      const polygonLot = polygon(lot.area!.coordinates)
      const polygonCenter = centroid(polygonLot)
      setCursor('grab')
      const showDDE =
        status.current.status === RiceLotStatus.EMERGENCE ||
        status.current.status === RiceLotStatus.EAR_APPEARANCE
      const today = new Date()
      const emergenceDays = dayjs(today).diff(lot.riceLot.emergenceDate, 'days')

      setPopupInfo({
        name: lot.name ?? '-',
        emergenceDays: showDDE && emergenceDays > 0 ? emergenceDays.toString() : '-',
        variety: lot.variety?.locales.es ?? '-',
        size: Math.round(lot.size),
        id: lot.id!,
        commercialExecutive: `${lot.seasonField?.season?.commercialExecutive?.name ?? ''} ${
          lot.seasonField?.season?.commercialExecutive?.lastName ?? ''
        }`,
        coordinates: [polygonCenter.geometry.coordinates[0], bboxLot[0][1] + 0.0008],
        companyName: lot.seasonField?.season?.company.name ?? '-',
      })
    },
    [lots, popupInfo],
  )

  const onHandleClickLuckerMap = useCallback(
    (event: MapLayerMouseEvent) => {
      if (luckerPopupInfo) {
        setLuckerPopupInfo(undefined)
        return
      }

      const { features } = event

      const feature = features?.[0]
      if (!feature) return

      const { id: clusterId } = feature.properties as { id: string }

      const { id: layerId } = feature.layer

      const id = clusterId ?? layerId

      const clickedLot = luckerMockData?.find(({ id: lotId }) => lotId === id)
      if (!clickedLot) return
      const {
        geojson,
        id: lotId,
        commercialExecutive: commercialExecutiveName,
        size,
        lotAge,
        category,
        average,
      } = clickedLot
      setCursor('grab')

      setLuckerPopupInfo({
        lotAge,
        category,
        average,
        size: Number(size),
        id: lotId,
        commercialExecutive: commercialExecutiveName,
        coordinates: geojson.coordinates[0][0][0],
      })
    },
    [luckerPopupInfo],
  )

  const interactiveLayerIds = useMemo(() => lots?.map(({ lot }) => lot.id!.toString()), [lots])
  const filteredLots = useMemo(() => {
    // We return an empty empty array because, if we doesn't have crops in one region
    // the query is not trigering and we may show the wrong number of lots.
    if (!selectedCrop?.id) return []

    return lots
  }, [lots, selectedCrop])

  return {
    lots: filteredLots ?? [],
    cursor,
    interactiveLayerIds,
    onMouseLeave,
    onHandleClickMap,
    onHandleClickLuckerMap,
    onMouseEnter,
    popupInfo,
    luckerPopupInfo,
    showPopupInfo,
    commercialExecutiveId: commercialExecutive?.id,
    coordinates: {
      latitude: coordinatesInfo.latitude,
      longitude: coordinatesInfo.longitude,
      zoom: coordinatesInfo.zoom,
    },
    loading,
    setCoordinates: setCoordinatesInfo,
    onCenterLots,
    lotsWithoutArea,
  }
}
