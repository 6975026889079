import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from 'styled-components'
import { useEffect, useState } from 'react'
import { ConfigProvider } from 'antd'

import { ApolloService } from 'services'
import { GlobalStyles, antdTheme, theme } from 'styles'
import {
  AuthContext,
  CropFilterContext,
  HistoricalSeasonFilterContext,
  RegionFilterContext,
  VarietyFilterContext,
} from 'contexts'
import { useAuth, useBrowserLogout } from 'hooks'
import { FullScreenLoader, PrivateRoute } from 'components'

import { LoginRouter, MainRouter } from './index'

const apolloClient = ApolloService.buildApolloClient()

const IS_LUCKER_DEMO = true

const Router: React.FC = () => {
  const { userToken, tokenLogin, userId, tokenLoginLoading } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const regionFilterContextValue = RegionFilterContext.useValue()
  const cropFilterContextValue = CropFilterContext.useValue()
  const varietyFilterContextValue = VarietyFilterContext.useValue()
  const historicalSeasonFilterContextValue = HistoricalSeasonFilterContext.useValue()
  const [loadingApp, setLoadingApp] = useState(!!userToken)
  useBrowserLogout()

  useEffect(() => {
    if (location.pathname === '/') navigate('/auth')
  }, [location, navigate])

  useEffect(() => {
    if (userToken && !userId) {
      const loginWithToken = () => {
        tokenLogin()
        setLoadingApp(false)
      }

      loginWithToken()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // If app initialization or user are loading, we show a full screen loader. This way, we can
  // assume that when using the user in different parts of the app, we already have it.
  if (loadingApp || tokenLoginLoading) return <FullScreenLoader />

  return (
    <Routes>
      <Route path="auth/*" element={<LoginRouter />} />
      <Route
        path="/*"
        element={
          IS_LUCKER_DEMO ? (
            <MainRouter />
          ) : (
            <RegionFilterContext.Provider value={regionFilterContextValue}>
              <CropFilterContext.Provider value={cropFilterContextValue}>
                <VarietyFilterContext.Provider value={varietyFilterContextValue}>
                  <HistoricalSeasonFilterContext.Provider
                    value={historicalSeasonFilterContextValue}
                  >
                    <PrivateRoute>
                      <MainRouter />
                    </PrivateRoute>
                  </HistoricalSeasonFilterContext.Provider>
                </VarietyFilterContext.Provider>
              </CropFilterContext.Provider>
            </RegionFilterContext.Provider>
          )
        }
      />
    </Routes>
  )
}

export const App: React.FC = () => {
  const authContextValue = AuthContext.useValue()

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <ConfigProvider theme={antdTheme}>
          <GlobalStyles />
          <AuthContext.Provider value={authContextValue}>
            <Router />
          </AuthContext.Provider>
        </ConfigProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}
