import { Link, useNavigate } from 'react-router-dom'

import { useIsProdEnv } from 'hooks'
import { Loader } from 'components'

import {
  FormEmailInput,
  FormPasswordInput,
  CenteredText,
  VerificationErrorText,
  WhiteVerificationErrorText,
  VerificationText,
} from '../../components'
import { LoginButton, Space, MainText, StyledLink } from './components'
import { useLogin, useLoginForm, useVerifyAccount } from './hooks'

const IS_LUCKER_DEMO = true

export const Login: React.FC = () => {
  const { control, handleSubmit } = useLoginForm()
  const navigate = useNavigate()
  const [login, { loading, error }] = useLogin()
  const isProdEnv = useIsProdEnv()
  const {
    loading: loadingVerification,
    accountVerificationText,
    isAccountVerified,
    isVerification,
    emailToValidate,
  } = useVerifyAccount()

  const onLogin = handleSubmit(credentials => login({ variables: credentials }))

  const luckerLogin = () => navigate('/map')

  const onPasswordInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onLogin()
    }
  }

  const verification = loadingVerification ? <Loader /> : accountVerificationText

  return (
    <>
      {!isProdEnv && (
        <MainText>
          {`¿Necesitas `}
          <StyledLink to={'signup'}>crear</StyledLink>
          {` una cuenta?`}
        </MainText>
      )}
      <Space direction="vertical" size={20}>
        <FormEmailInput
          control={control}
          defaultValue={isAccountVerified && emailToValidate ? emailToValidate : undefined}
        />
        <FormPasswordInput control={control} onKeyUp={onPasswordInputKeyUp} />
      </Space>
      <LoginButton
        loading={loading}
        type="primary"
        onClick={IS_LUCKER_DEMO ? luckerLogin : onLogin}
      >
        Iniciar sesión
      </LoginButton>
      {error && (
        <VerificationErrorText error>
          Usuario o contraseña incorrectos. <br />
          <WhiteVerificationErrorText>
            {`Si no estás registrado puedes comunicarte con nosotros: `}
          </WhiteVerificationErrorText>
          <a href="mailto:info@kuna.app">info@kuna.app</a>
        </VerificationErrorText>
      )}
      <VerificationText error={isVerification && !isAccountVerified}>
        {isVerification && verification}
      </VerificationText>
      <CenteredText>
        <Link to={'forgot-password'}>¿Olvidaste tu contraseña?</Link>
      </CenteredText>
    </>
  )
}
